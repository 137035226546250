.sfc-accordion.accordion {
  background-color: transparent;
}

.sfc-accordion .accordion-item {
  border: none;
  border-bottom: 1px solid #E0E0E0;
}

.sfc-accordion .accordion-item:last-of-type, .sfc-accordion .accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
  border-radius: 0;
}

.sfc-accordion .accordion-button {
  font-size: 1.125rem;
  font-weight: 700;
  padding: 25px 0px;
}

.sfc-accordion .accordion-button::after {
  width: 22px;
  height: 22px;
  background-size: 24px;
}

.sfc-accordion .accordion-button:not(.collapsed) {
  background-color: transparent;
  box-shadow: none;
}

.sfc-accordion .accordion-button:focus {
  box-shadow: none;
}

.sfc-accordion .accordion-body {
  padding: 0 0 20px 0;
}